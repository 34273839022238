let resumeData = {
  imagebaseurl: "",
  name: "Shimon Wosner",
  role: "Full-stack web developer",
  // linkedinId: "Your LinkedIn Id",
  // skypeid: "Your skypeid",
  email: "shwosner@gmail.com",
  roleDescription: "I enjoy coding and I love to learn about new technologies.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/shimon-wosner",
      className: "fa fa-linkedin"
    },
    {
      name: "github",
      url: "https://github.com/shwosner",
      className: "fa fa-github"
    },
    {
      name: "twitter",
      url: "https://twitter.com/shwosner",
      className: "fa fa-twitter"
    }
  ],
  aboutme: "I am a self taught Full Stack Web Developer",
  address: "845-274-2066",
  website: "https://www.shimon.pro",
  education: [
    // {
    //   UniversityName: "The LNM Insitute of Information Technology",
    //   specialization: "Some specialization",
    //   MonthOfPassing: "Aug",
    //   YearOfPassing: "2020",
    //   Achievements: "Some Achievements"
    // },
    // {
    //   UniversityName: "Some University",
    //   specialization: "Some specialization",
    //   MonthOfPassing: "Jan",
    //   YearOfPassing: "2018",
    //   Achievements: "Some Achievements"
    // }
  ],
  work: [
    {
      CompanyName: "OKG Tech",
      specialization: "HelpDesk manager / dispatcher",
      MonthOfLeaving: "As of Nov",
      YearOfLeaving: "2019",
      Achievements: "Web developing, team managing"
    },
    {
      CompanyName: "Techloq",
      specialization: "Customer support",
      MonthOfLeaving: "Left Mar",
      YearOfLeaving: "2018"
      // Achievements: "Man Achievements"
    }
  ],
  skillsDescription: "Some of my skills",
  skills: [
    {
      skillname: "HTML5"
    },
    {
      skillname: "CSS"
    },
    {
      skillname: "Reactjs"
    },
    {
      skillname: "Nodejs"
    },
    {
      skillname: "Git"
    },
    {
      skillname: "APIs"
    }
  ],
  portfolio: [
    {
      name: "Dirshu Siyum UK",
      description: "Website",
      imgurl: "images/portfolio/dirshu.png"
    }
    // {
    //   name: "project2",
    //   description: "mobileapp",
    //   imgurl: "images/portfolio/project.jpg"
    // },
    // {
    //   name: "project3",
    //   description: "mobileapp",
    //   imgurl: "images/portfolio/project2.png"
    // },
    // {
    //   name: "project4",
    //   description: "mobileapp",
    //   imgurl: "images/portfolio/phone.jpg"
    // }
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy"
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy"
    }
  ]
};

export default resumeData;
